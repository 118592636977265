export const state = () => ({
  locale: "fr",
  locales: ["fr", "gb"],
  talk: false,
  name: "© ACHMITECH",
  reserved: "All rights reserved",
  video: true,
  menus: [
    { title: "menu.home", link: "/" },
    { title: "menu.solutions", link: "/solutions" },
    { title: "menu.about", link: "/about" },
    { title: "menu.perspectives", link: "/perspectives" },
    { title: "menu.careers", link: "/careers" },
    // { title: "menu.contact", link: "/contact" },
  ],
  socialNetworks: [
    {
      title: "linkedin",
      link: "https://www.linkedin.com/company/achmitech/",
      icon: "linkedin",
    },
    { title: "facebook", link: "https://www.facebook.com/profile.php?id=61554914202918", icon: "facebook" },
    // { title: "twitter", link: "", icon: "twitter" },
    { title: "instagram", link: "https://www.instagram.com/achmitech_official/", icon: "instagram" },
    // { title: "youtube", link: "", icon: "youtube" },
  ],

  responses: [
    "home.question.r1",
    "home.question.r2",
    "home.question.r3",
    // "home.question.r4",
  ],
  responses_images: [
    { img: "Experience-1.avif", class: "experience-1" },
    { img: "Experience-2.avif", class: "experience-2" },
  ],
  collaboration: [
    {
      logo: "client-1",
      title: "home.collaboration.bloc1.title",
      description: "home.collaboration.bloc1.description",
      show: false,
    },
    {
      logo: "client-2",
      title: "home.collaboration.bloc2.title",
      description: "home.collaboration.bloc2.description",
      show: false,
    },
    {
      logo: "client-3",
      title: "home.collaboration.bloc3.title",
      description: "home.collaboration.bloc3.description",
      show: false,
    },
  ],
  solutions: [
    {
      logo: "service-1",
      title: "home.solutions.bloc5.title",
      description: "home.solutions.bloc5.description",
      show: false,
      link: "/solutions",
    },
    {
      logo: "service-2",
      title: "home.solutions.bloc9.title",
      description: "home.solutions.bloc9.description",
      show: false,
      link: "/solutions",
    },
    {
      logo: "service-3",
      title: "home.solutions.bloc3.title",
      description: "home.solutions.bloc3.description",
      show: false,
      link: "/solutions",
    },
    {
      logo: "service-4",
      title: "home.solutions.bloc4.title",
      description: "home.solutions.bloc4.description",
      show: false,
      link: "/solutions",
    },
    {
      logo: "service-5",
      title: "home.solutions.bloc7.title",
      description: "home.solutions.bloc7.description",
      show: false,
      link: "/solutions",
    },
    {
      logo: "service-6",
      title: "home.solutions.bloc1.title",
      description: "home.solutions.bloc1.description",
      show: false,
      link: "/solutions",
    },
    {
      logo: "service-7",
      title: "home.solutions.bloc6.title",
      description: "home.solutions.bloc6.description",
      show: false,
      link: "/solutions",
    },
    {
      logo: "service-8",
      title: "home.solutions.bloc2.title",
      description: "home.solutions.bloc2.description",
      show: false,
      link: "/solutions",
    },
    {
      logo: "service-9",
      title: "home.solutions.bloc8.title",
      description: "home.solutions.bloc8.description",
      show: false,
      link: "/solutions",
    },
  ],
  avenir: [
    {
      logo: "avenir-2",
      title: "home.avenir.bloc1.title",
      description: "home.avenir.bloc1.description",
      show: false,
    },
    {
      logo: "avenir-3",
      title: "home.avenir.bloc2.title",
      description: "home.avenir.bloc2.description",
      show: false,
    },
    {
      logo: "avenir-1",
      title: "home.avenir.bloc3.title",
      description: "home.avenir.bloc3.description",
      show: false,
    },
    {
      logo: "avenir-4",
      title: "home.avenir.bloc4.title",
      description: "home.avenir.bloc4.description",
      show: false,
    },
  ],
  solutions_images: [{ img: "solution-1.svg", class: "solution" }],
  perspectives_images: [{ img: "Actua.svg", class: "perspectives" }],
  carers_images: [{ img: "Cariere.svg", class: "carers" }],
  about_images: [{ img: "A_propos.svg", class: "about" }],
  clients: [
    { title: "asics", img: "client-1.png", link: "https://www.google.com" },
    { title: "thf", img: "client-2.png", link: "https://www.google.com" },
    // { title: "kh", img: "client-3.svg", link: "https://www.google.com" },
    // { title: "asics", img: "client-1.png", link: "https://www.google.com" },
    // { title: "thf", img: "client-2.png", link: "https://www.google.com" },
    // { title: "kh", img: "client-3.svg", link: "https://www.google.com" },
  ],
  statistic: [
    {
      icon: "emoji-smile",
      value: "96%",
      text: "home.parallax.statistic.text1",
    },
    {
      icon: "bag",
      value: "8",
      text: "home.parallax.statistic.text2",
    },
    {
      icon: "house-door",
      value: "3",
      text: "home.parallax.statistic.text3",
    },
    // {
    //   icon: "person-lines-fill",
    //   value: "2+",
    //   text: "home.parallax.statistic.text4",
    // },
  ],
  chezachmitech: [
    {
      description: "home.chezachmitech.bloc4.description",
      title: "home.chezachmitech.bloc4.title",
      subtitle: "home.chezachmitech.bloc4.subtitle",
    },
    {
      description: "home.chezachmitech.bloc5.description",
      title: "home.chezachmitech.bloc5.title",
      subtitle: "home.chezachmitech.bloc5.subtitle",
    },
    {
      description: "home.chezachmitech.bloc6.description",
      title: "home.chezachmitech.bloc6.title",
      subtitle: "home.chezachmitech.bloc6.subtitle",
    },
  ],
  nouvelles: [
    {
      title: "home.nouvelles.bloc5.title",
    },
  ],
  temoignages: [
    {
      img: "stars.svg",
      description: "home.temoignage.bloc1.description",
      person: "home.temoignage.bloc1.person",
      job: "home.temoignage.bloc1.job",
      postuler: "home.temoignage.bloc1.postuler",
    },
    {
      img: "stars.svg",
      description: "home.temoignage.bloc2.description",
      person: "home.temoignage.bloc2.person",
      job: "home.temoignage.bloc2.job",
      postuler: "home.temoignage.bloc2.postuler",
    },
    {
      img: "stars.svg",
      description: "home.temoignage.bloc3.description",
      person: "home.temoignage.bloc3.person",
      job: "home.temoignage.bloc3.job",
      postuler: "home.temoignage.bloc3.postuler",
    },
    {
      img: "stars.svg",
      description: "home.temoignage.bloc4.description",
      person: "home.temoignage.bloc4.person",
      job: "home.temoignage.bloc4.job",
      postuler: "home.temoignage.bloc4.postuler",
    },
    {
      img: "stars.svg",
      description: "home.temoignage.bloc5.description",
      person: "home.temoignage.bloc5.person",
      job: "home.temoignage.bloc5.job",
      postuler: "home.temoignage.bloc5.postuler",
    },
    {
      img: "stars.svg",
      description: "home.temoignage.bloc6.description",
      person: "home.temoignage.bloc6.person",
      job: "home.temoignage.bloc6.job",
      postuler: "home.temoignage.bloc6.postuler",
    },
    {
      img: "stars.svg",
      description: "home.temoignage.bloc7.description",
      person: "home.temoignage.bloc7.person",
      job: "home.temoignage.bloc7.job",
      postuler: "home.temoignage.bloc7.postuler",
    },
    {
      img: "stars.svg",
      description: "home.temoignage.bloc8.description",
      person: "home.temoignage.bloc8.person",
      job: "home.temoignage.bloc8.job",
      postuler: "home.temoignage.bloc8.postuler",
    },
    {
      img: "stars.svg",
      description: "home.temoignage.bloc9.description",
      person: "home.temoignage.bloc9.person",
      job: "home.temoignage.bloc9.job",
      postuler: "home.temoignage.bloc9.postuler",
    },
    {
      img: "stars.svg",
      description: "home.temoignage.bloc10.description",
      person: "home.temoignage.bloc10.person",
      job: "home.temoignage.bloc10.job",
      postuler: "home.temoignage.bloc10.postuler",
    },
  ],
});

export const mutations = {
  SET_LOCALES(mutate_state, payload) {
    mutate_state.locales = payload;
  },
  SET_LOCALE(mutate_state, payload) {
    if (mutate_state.locales.indexOf(payload) !== -1) {
      mutate_state.locale = payload;
    }
  },
  SET_TALK(mutate_state, payload) {
    mutate_state.talk = payload;
  },
  UPDATE_SOLUTION(mutate_state, payload) {
    let arr = [...mutate_state.solutions].map((item) => ({
      ...item,
      show: false,
    }));
    mutate_state.solutions = [];
    const index = arr.findIndex((item) => item.title == payload.title);
    if (index != -1) arr[index] = { ...payload };
    mutate_state.solutions = [...arr];
  },
};

export const actions = {
  setListLocales({ commit }, payload) {
    commit("SET_LOCALES", payload);
  },
  setDefaultLocale({ commit }, payload) {
    commit("SET_LOCALE", payload);
  },
  setTalk({ commit }, payload) {
    commit("SET_TALK", payload);
  },
};
